@import url("https://fonts.googleapis.com/css?family=Sacramento&display=swap");

.review {
  margin-top: 80px;
    color: white;
    display: flex;
    justify-content: center;

    .review-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .greeting {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 12px;
      padding-bottom: 40px;
    }

    .greeting-text {
      position: relative;
      padding: 5px 10px;
      color: black;
      background-color: white;
      border-radius: 15px;
    }

    .greeting-text:after {
      content: '';
      position: absolute;
      left: 3px;
      top: 50%;
      width: 0;
      height: 0;
      border: 8px solid transparent;
      border-right-color: #ffffff;
      border-left: 0;
      margin-top: -8px;
      margin-left: -8px;
    }

    button {
      /* button styles */
      background-color: #b3b3b3;
      border: none;
      color: white;
      padding: 10px 20px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 4px 2px;
      cursor: pointer;
      border-radius: 10px;
    }
    
    button:hover {
      /* change button color on hover */
      background-color: #8c8c8c;
    }
    
    .winter-button {
      /* add Christmas-specific styles */
      background-color: #1e90ff;
      color: white;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .user {
        height: 40px;
        background-color: white;
        border-radius: 5px;
        color: black;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 3px;

    }

    .word-status-section {
      display: flex;
      flex-direction: row;
    }

    .submitted-section {
        margin-top: 40px;
        margin-right: 10px;
        .users {
          flex-wrap: wrap;
          display: flex;
          flex-direction: column;
        }
    }
    .unsubmitted-section {
        margin-top: 40px;
        margin-left: 10px;
    }

    .section-title {
        font-size: 14px;
    }

    .submitted {
        background-color: rgb(78, 145, 78);
    }

    .waiting {
        background-color: rgb(252, 111, 111);
    }

    .label-text {
        font-size: 26px;
        align-self: flex-end;
    }

    .small {
      font-size: 14px;
      text-transform: uppercase;
    }

    .secret-word {
      font-size: 20px;
      -webkit-text-stroke: 1px white;
    }

    .bold {
      font-weight: bold;
    }


    .personal-word, .santa-word {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .secret-word {
          margin-left: 10px;
          background: none;
          border: none;
          border-bottom: 1px solid white;
          padding: 0 15px;
          color: rgba(0, 0, 0, 0);
          font-weight: bold;
          font-size: 48px;
          -webkit-text-stroke: 2px white;
      }
        

    }

    .word-section {
        display: flex;
        flex-direction: column;
        
    }
    .saved {
        padding:2px;
        font-size: 12px;
        color: rgb(92, 212, 92);
        align-self: flex-end;
    }

    .unsaved {
        padding:2px;
        font-size: 12px;
        color: rgb(212, 92, 92);
        align-self: flex-end;
    }

    .saving {
        padding:2px;
        font-size: 12px;
        color: rgb(92, 158, 212);
        align-self: flex-end;
    }

    .glow {
        color: #fff;
        text-align: center;
        -webkit-animation: glow 1s ease-in-out infinite alternate;
        -moz-animation: glow 1s ease-in-out infinite alternate;
        animation: glow 1.5s ease-in-out infinite alternate;
      }
      
      @keyframes glow {
        from {
          text-shadow: 0 0 1px rgba(255, 255, 255, 0.185), 0 0 2px rgba(255, 255, 255, 0.24), 0 0 3px #0077e61f, 0 0 4px #008ae634, 0 0 5px #0064e6, 0 0 6px #006be6, 0 0 7px #0051e6;
        }
        to {
          text-shadow: 0 0 4px rgba(255, 255, 255, 0.5), 0 0 7px #f9f9fa7c, 0 0 10px #4d8bff8f, 0 0 13px #4d94ff, 0 0 16px #4d8bff80, 0 0 20px #4d7cff81, 0 0 24px #f9f9fa8a;
        }
      }

.warmglow {
  font-size: calc(20px + 18vh);
/*   text-shadow: 0 0 5px #f562ff, 0 0 15px #f562ff, 0 0 25px #f562ff,
    0 0 20px #f562ff, 0 0 30px #890092, 0 0 80px #890092, 0 0 80px #890092;
  color: #fccaff; */
  text-shadow: 0 0 1px #ffa500, 0 0 3px #ffa500, 0 0 5px #ffa500, 0 0 10px #ffa500, 0 0 20px #ff0000, 0 0 4px #ff8d00, 0 0 40px #ff0000;
    color: #fff6a960;
  font-family: "Sacramento", cursive;
  text-align: center;
  animation: blink 12s infinite;
  -webkit-animation: blink 12s infinite;
}


@keyframes blink {
  20%,
  24%,
  55% {
    color: #111;
    text-shadow: none;
  }

  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
/*     color: #fccaff;
    text-shadow: 0 0 5px #f562ff, 0 0 15px #f562ff, 0 0 25px #f562ff,
      0 0 20px #f562ff, 0 0 30px #890092, 0 0 80px #890092, 0 0 80px #890092; */
      text-shadow: 0 0 1px #ffa500, 0 0 3px #ffa500, 0 0 5px #ffa500, 0 0 10px #ffa500, 0 0 20px #ff0000, 0 0 4px #ff8d00, 0 0 40px #ff0000;
         color: #fff6a948;
  }
}

@keyframes blink {
  20%,
  24%,
  55% {
    color: #111;
    text-shadow: none;
  }

  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
/*     color: #fccaff;
    text-shadow: 0 0 5px #f562ff, 0 0 15px #f562ff, 0 0 25px #f562ff,
      0 0 20px #f562ff, 0 0 30px #890092, 0 0 80px #890092, 0 0 80px #890092; */
      text-shadow: 0 0 1px #ffa500, 0 0 3px #ffa500, 0 0 5px #ffa500, 0 0 10px #ffa500, 0 0 20px #ff0000, 0 0 4px #ff8d00, 0 0 40px #ff0000;
    color: #fff6a946;
  }
}

.colorful{
  text-transform: capitalize;
    font-size: 6rem;
    padding: 0 10px;
  text-align: center;
  color: #fcedd8;
  border-radius: 20px;
  font-family: 'Sacramento', cursive;
  font-weight: 700;
text-shadow: 2px 2px 0px #eb452b, 
            4px 4px 0px #efa032, 
            6px 6px 0px #46b59b, 
            8px 8px 0px #017e7f, 
            10px 10px 0px #052939, 
            12px 12px 0px #c11a2bd0, 
}

@media screen and (max-width: 767px) {
  .label-text {
      font-size: 20px;
  }

  .small {
    font-size: 12px;
  }

  .colorful {
    font-size: 4rem;
  }

  .personal-word {
    .secret-word {
      font-size: 32px;
    }
  }
}

}