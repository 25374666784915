
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

body {
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
    overflow: hidden;
  }

  .glowHelper {
    position: absolute;
    top: -400px;
    left: 0;
    height: 400px;
    width: 100%;
    color: white;
    text-shadow: inset 0px 0px 10px black;
    -webkit-box-shadow:0px 0px 103px 6px rgba(0,187,255,0.59);
    -moz-box-shadow: 0px 0px 103px 6px rgba(0,187,255,0.59);
    box-shadow: 0px 0px 103px 6px rgba(0,187,255,0.59);
  }

.App-link {
  color: #61dafb;
}

.title {
  /* position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%); */
  color: white;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 150px;
  font-size: 60px;
  font-family: 'Quicksand';
  text-shadow: rgba(0,204,255,0.99) 0px 0px 30px;
  /* text-shadow: inset 0px 0px 10px black;
  -webkit-box-shadow:0px 0px 103px 6px rgba(0,187,255,0.59);
  -moz-box-shadow: 0px 0px 103px 6px rgba(0,187,255,0.59);
  box-shadow: 0px 0px 103px 6px rgba(0,187,255,0.59); */
} 

.subtitle {
  color: white;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 12px;
  font-family: 'Quicksand';
  text-shadow: inset 0px 0px 10px black;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .title {
    font-size: 36px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
