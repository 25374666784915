.login {
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .failure-message {
        color: red;
        align-self: flex-start;
        font-size: 11px;
        padding-bottom: 10px;
    }

    .login-box {
        // background-color: #efebe5;
        // background-color: #90aacf;
        width: 15%;
        height: 45%;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        input {
            // background-color: #626d71;
            background-color: #efebe5;
            margin: 0 0 20px 0;
            height: 40px;
            border: none;
            border-radius: 10px;
            box-shadow: inset 1px 1px 3px #232324;
            padding-left: 15px;
            font-size: 16px;
            color: #233753;
        }

        label {
            font-size: 18px;
            font-weight: bold;
            color:#efebe5;
            align-self: flex-start;
            padding-left: 5px;
        }

        .footer {
            display: flex;
            flex-direction: row;
            height: 20px;
            color: white;
            justify-content: flex-end;
        }

        .link {
            padding: 0 5px;
            text-transform: uppercase;

            &:hover {
                color: #90aacf;
                cursor: pointer;
            }
        }
    }

    @media screen and (max-width: 767px) {
        .login-box {
            width: 50%;
        }
    }
    

}